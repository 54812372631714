<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view />
    <div class="update-dialog" v-if="prompt">
      <div class="update-dialog__content"> A new version is found. Refresh to load it? </div>
      <div class="update-dialog__actions">
        <button class="update-dialog__button update-dialog__button--confirm" @click="update"> Update </button>
        <button class="update-dialog__button update-dialog__button--cancel" @click="prompt = false"> Cancel </button>
      </div>
    </div>
  </div>
</template>

<script>
import serviceNotifications from '@/services/notifications';
import serviceGeneral from '@/services/users';
export default {
  data() {
    return {
      prompt: false,
    };
  },
  created() {
    if (this.$store.getters['session/loggedIn']) {
      this.register();
      this.fetchSettings();
    }
  },
  methods: {
    async update() {
      this.prompt = false;
      await this.$workbox.messageSW({ type: 'SKIP_WAITING' });
    },
    fetchSettings() {
      serviceGeneral.fetchSettings().then((response) => {
        this.$store.commit('settings', response.data);
      });
    },
    async register() {
      window.addEventListener('load', async () => {
        // console.log('Registering a Service worker');
        await navigator.serviceWorker.register('/worker.js');
        await this.subscribe();
        // console.log('ready to receive web push');
      });
    },
    async subscribe() {
      // console.log('Suscribing webpush!');
      const serviceWorker = await navigator.serviceWorker.ready;
      const subscription = await serviceWorker.pushManager.getSubscription();
      if (!subscription) {
        // console.log('Listening Push Notifications');
        const push = await serviceWorker.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: this.urlBase64ToUint8Array(process.env.VUE_APP_NOTIFIER_PUBLIC_VAPID_KEY),
        });
        await serviceNotifications.suscribe(push);
      }
    },
    urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
  },
};
</script>

<style lang="scss">
@import '~nprogress/nprogress.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

#nprogress .bar {
  background: #2c3e50;
}

.update-dialog {
  position: fixed;
  left: 50%;
  bottom: 64px;
  transform: translateX(-50%);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 12px;
  max-width: 576px;
  color: white;
  background-color: #2c3e50;
  text-align: left;
  &__actions {
    display: flex;
    margin-top: 8px;
  }
  &__button {
    margin-right: 8px;
    &--confirm {
      margin-left: auto;
    }
  }
}
</style>
